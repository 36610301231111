<template>
  <article v-if="poster" class="posters-card">
    <router-link class="posters-card__inner" :to="{ name: 'poster', params: { id: poster.id } }">
      <ImgComponent :img="poster.img" class="posters-card__image" />
      <div class="posters-card__blackout"></div>
      <div class="posters-card__content">
        <div class="posters-card__tags">
          <div v-if="poster.date" class="posters-card__tag posters-card__tag--date">
            <span>{{ poster.date | humanDateTime }}</span>
          </div>
          <div v-if="poster.limit" class="posters-card__tag posters-card__tag--limit">
            <span>{{ poster.limit }} +</span>
          </div>
        </div>
        <div class="posters-card__text">
          <span v-if="poster.title" class="posters-card__title">{{ poster.title }}</span>
          <span v-if="poster.subtitle" class="posters-card__subtitle">
            {{ poster.subtitle }}
          </span>
          <span v-if="poster.place" class="posters-card__place">{{ poster.place }}</span>
        </div>
      </div>
    </router-link>
  </article>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
export default {
  name: "PostersCardComponent",
  props: {
    poster: Object,
  },
  components: {
    ImgComponent,
  },
};
</script>

<style lang="stylus">
.posters-card {
  width 100%
  height 350px
  border-radius 5px
  overflow hidden

  &__inner {
    position relative
    display grid
    width 100%
    height 100%
  }

  &__image {
    absolute left top
    width 100%
    height 100%
  }

  &__blackout {
    absolute left top
    width 100%
    height 100%
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 52.08%, rgba(0, 0, 0, 0.82) 76.56%, #000000 100%);
    mix-blend-mode normal
    opacity 0.8
  }

  &__content {
    display flex
    flex-direction column
    justify-content space-between
    z-index 1
    color var(--white)
    padding 28px 28px 25px
    +below(900px) {
      padding 18px 18px 15px
    }
  }

  &__tags {
    display flex
    gap 10px
  }

  &__tag {
    display flex
    align-items center
    padding 5px 15px
    border-radius 33px

    span {
      font-size .875em
      font-weight 500
      line-height 17px
    }

    &--date {
      background var(--main)
    }

    &--limit {
      background var(--white_2)
      color var(--black)
    }
  }

  &__text {
    display grid
    grid-gap 10px
  }

  &__title {
    font-size 1.25rem
    font-weight 500
    line-height 30px
    +below(900px) {
      font-size 1.125rem
      line-height 1.625rem
    }
  }

  &__subtitle {
    font-size .875rem
    line-height 16px
    color var(--gray_2)
    +below(900px) {
      font-size .75rem
      line-height 1.375rem
    }
  }

  &__place {
    font-size .75rem
    line-height 16px
    color var(--gray_2)
  }
}
</style>
